import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { styled, experimental_sx as sx } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import Moment from 'moment';
import * as propz from 'propz';
import { School } from 'models/school';
import { Tournament } from 'models/tournament';
import { SCREEN_SIZE } from 'consts/responsive';
import { PUBLIC_SITE_STATUS_OPTIONS, TOURNAMENT_DETAILS_TABS, TOURNAMENT_DETAILS_TABS_ARRAY } from 'consts/tournament';
import { getSchoolTournament } from 'services/school';
import { getTournamentPublicSiteLink } from 'helpers/link';
import { getTournamentBookingLink } from 'helpers/tournament';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TabsMUI from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PageWrapper from 'components/PageWrapper';
import Details from 'views/School/Tournaments/TournamentDetails/Details';
import GoverningBodies from 'views/School/Tournaments/TournamentDetails/GoverningBodies';
import Documents from 'views/School/Tournaments/TournamentDetails/Documents';
import Loader from 'components/loader/loader';

interface Props {
   school: School;
}

const TournamentNameTitle = styled(Typography)(
   sx({
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '1px',
      my: {
         xs: 2,
         sm: 3
      },
      lineHeight: {
         xs: 1,
         sm: 1.6
      }
   })
);

const GoBackButton = styled(Button)(
   sx({
      mt: 1,

      '& .MuiButton-startIcon': {
         mr: 0
      }
   })
);

const TabsWrapper = styled(Box)(
   sx({
      mt: {
         sm: 4,
         xs: 2
      }
   })
);

const Tabs = styled(TabsMUI)(
   sx({
      mb: 2,

      '& .MuiTabs-flexContainer': {
         borderBottom: '2px solid #ccc'
      },

      '& .Mui-selected': {
         background: '#1976d229'
      }
   })
);

const DateAndActionsWrapper = styled(Box)(
   sx({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
   })
);

const ActionsWrapper = styled(Box)(
   sx({
      display: 'flex',
      gap: 2
   })
);

const WaitingListBox = styled(Box)(
   sx({
      p: 2,
      backgroundColor: '#f5f5f5',
      borderRadius: '4px',
      mt: 2
   })
);

export default function SchoolTournamentView(props: Props): JSX.Element {
   const pageParams = useParams();
   const navigate = useNavigate();

   const isTabletScreenSize = useMediaQuery(SCREEN_SIZE.TABLET);

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [tournament, setTournament] = useState<Tournament>();
   const [tabIndex, setTabIndex] = useState(0);

   const { school } = props;
   const isTournamentExist = typeof tournament !== 'undefined';

   const schoolId = propz.get(school, ['id'], '');
   const tournamentId = propz.get(pageParams, ['id'], '');

   const now = new Date();

   const tournamentName = propz.get(tournament, ['name'], '');
   const sportName = propz.get(tournament, ['sport', 'name'], '');

   const tournamentStartTime = propz.get(tournament, ['startTime'], '');
   const tournamentStartTimeFormatted = Moment(tournamentStartTime).format('dddd, MMMM Do YYYY, hh:mm a');

   const autoEnrollmentTeamsSettings = propz.get(tournament, ['autoEnrollmentTeamsSettings'], []);
   const entriesOpen = propz.get(tournament, ['entriesOpen'], '');
   const deadlineForEntries = propz.get(tournament, ['deadlineForEntries'], '');
   const tournamentWebsiteLink = isTournamentExist ? getTournamentPublicSiteLink(tournament) : '';
   const isEntriesOpenExist = entriesOpen !== '';
   const isDeadlineForEntriesExist = deadlineForEntries !== '';
   const isEntriesOpenDatePassed = new Date(entriesOpen) < now;
   const isDeadlineForEntriesPassed = new Date(deadlineForEntries) < now;
   const isAutoEnrollmentToWaitingList = propz.get(tournament, ['isAutoEnrollmentToWaitingList'], false);
   const waitingListCount = propz.get(tournament, ['waitingListCount'], 0); // Extract waitingListCount
   const publicSiteStatus = propz.get(tournament, ['publicSite', 'status'], '');
   const isPublicSiteAvailable = publicSiteStatus === PUBLIC_SITE_STATUS_OPTIONS.PUBLIC_AVAILABLE;

   const isSpacesLeft = autoEnrollmentTeamsSettings.some((setting) => {
      const currentNumberTeams = propz.get(setting, ['currentNumberTeams']);
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      const spacesLeft = maximumNumberTeams - currentNumberTeams;

      return spacesLeft > 0;
   });

   const isSomeMaximumNumberTeamsEqualsZero = autoEnrollmentTeamsSettings.some((setting) => {
      const maximumNumberTeams = propz.get(setting, ['maximumNumberTeams']);

      return maximumNumberTeams === 0;
   });

   const isBookOnlineButtonShow = isEntriesOpenExist &&
      isEntriesOpenDatePassed &&
      isDeadlineForEntriesExist &&
      !isDeadlineForEntriesPassed;

   const bookOnlineButtonText = (!isSpacesLeft || isAutoEnrollmentToWaitingList) && !isSomeMaximumNumberTeamsEqualsZero
      ? `Book onto WL (${waitingListCount})`
      : 'Book online';

   useEffect(() => {
      setIsLoading(true);

      getSchoolTournament(schoolId, tournamentId).then((tournament) => {
         setTournament(tournament);
         setIsLoading(false);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const renderTabs = () => {
      return TOURNAMENT_DETAILS_TABS_ARRAY.map((tab, index) => (
         <Tab
            sx={{ flex: '1 1 auto' }}
            key={`vertical-tab-${index}`}
            label={tab}
            id={`vertical-tab-${index}`}
            aria-controls={`vertical-tabpanel-${index}`}
         />
      )
      )
   };

   const renderTabContent = () => {
      const currentTab = TOURNAMENT_DETAILS_TABS_ARRAY[tabIndex];

      let tabContent;

      switch (currentTab) {
         case TOURNAMENT_DETAILS_TABS.DETAILS:
            tabContent = isTournamentExist && <Details tournament={tournament} school={school} />

            break;

         case TOURNAMENT_DETAILS_TABS.GOVERNINIG_BODIES:
            tabContent = isTournamentExist && <GoverningBodies tournament={tournament} school={school} />

            break;

         case TOURNAMENT_DETAILS_TABS.DOCUMENTS:
            tabContent = isTournamentExist && <Documents tournament={tournament} />

            break;
      }

      return (
         <Box
            role='tabpanel'
            id={`vertical-tabpanel-${tabIndex}`}
            aria-labelledby={`vertical-tab-${tabIndex}`}
         >
            {tabContent}
         </Box>
      );
   };

   const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
   };

   const onBookOnlineClick = () => {
      const url = getTournamentBookingLink(tournamentId);

      window.open(url, '_blank');
   };

   const onGoBackClick = () => {
      if (window.history.length > 1) {
         navigate(-1);
      } else {
         navigate('/');
      };
   };

   if (isLoading) {
      return (
         <Loader />
      );
   }

   return (
      <PageWrapper>
         <GoBackButton
            variant='contained'
            startIcon={<ArrowBackIcon />}
            size={isTabletScreenSize ? 'small' : 'medium'}
            onClick={onGoBackClick}
         />

         <TournamentNameTitle variant={isTabletScreenSize ? 'h6' : 'h5'}>{tournamentName}</TournamentNameTitle>

         <Typography color='primary'>Sport: {sportName}</Typography>

         <DateAndActionsWrapper>
            <Typography variant={isTabletScreenSize ? 'subtitle1' : 'h6'} color='primary' sx={{ fontWeight: 'bold' }}>
               {tournamentStartTimeFormatted}
            </Typography>

            <ActionsWrapper>
               {isPublicSiteAvailable &&
                  <Button
                     variant="outlined"
                     size="small"
                     href={tournamentWebsiteLink}
                     target='_blank'
                     sx={{ whiteSpace: 'nowrap' }}
                  >
                     Tournament website
                  </Button>
               }

               {isBookOnlineButtonShow &&
                  <Button
                     onClick={onBookOnlineClick}
                     color='error'
                     variant='outlined'
                  >
                     {bookOnlineButtonText}
                  </Button>
               }
            </ActionsWrapper>
         </DateAndActionsWrapper>

         {/* Add Waiting List Count Here */}
         {isAutoEnrollmentToWaitingList && (
            <WaitingListBox>
               <Typography variant="body2" color="textSecondary">
                  Number of teams in waiting list: {waitingListCount}
               </Typography>
            </WaitingListBox>
         )}

         <TabsWrapper>
            <Tabs
               variant="fullWidth"
               allowScrollButtonsMobile={isTabletScreenSize}
               value={tabIndex}
               onChange={onTabChange}
               aria-label='tabs'
            >
               {renderTabs()}
            </Tabs>

            {renderTabContent()}
         </TabsWrapper>
      </PageWrapper>
   );
};
